<template>
  <div>
    <!--  -->
    <div class="mt-10 mb-7">
      <div class="text-h4 text-center">注册一个账号</div>
    </div>

    <!-- 注册成功提示 -->
    <v-container v-if="view">
      <div class="mt-6 mb-2">
        <div class="text--secondary text-center">注册成功</div>
        <div class="text--secondary text-center">{{ text }}</div>
      </div>
      <v-row justify="center" class="mt-4">
        <v-btn to="/login" class="mx-auto" depressed color="primary">返回登录</v-btn>
      </v-row>
    </v-container>
    <!-- 注册成功提示 -->


    <!-- 注册表单 -->
    <v-container v-if="!view">
      <v-alert border="left" colored-border :color="reg_config.color" elevation="2" :icon="reg_config.icon">
        {{ reg_config.announcement }}
      </v-alert>

      <v-card class="my-5" v-show="reg_config.open_register">
        <v-card-text>
          <p class="text-center text-subtitle-2">
            一键注册
          </p>
          <v-row no-gutters justify="center">
            <v-btn outlined href="/auth/google" color="#8a8a8a">
              <svg t="1656592905871" class="icon" viewBox="0 0 1024 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="4360" width="20" height="20">
                <path
                  d="M214.101333 512c0-32.512 5.546667-63.701333 15.36-92.928L57.173333 290.218667A491.861333 491.861333 0 0 0 4.693333 512c0 79.701333 18.858667 154.88 52.394667 221.610667l172.202667-129.066667A290.56 290.56 0 0 1 214.101333 512"
                  fill="#FBBC05" p-id="4361"></path>
                <path
                  d="M516.693333 216.192c72.106667 0 137.258667 25.002667 188.458667 65.962667L854.101333 136.533333C763.349333 59.178667 646.997333 11.392 516.693333 11.392c-202.325333 0-376.234667 113.28-459.52 278.826667l172.373334 128.853333c39.68-118.016 152.832-202.88 287.146666-202.88"
                  fill="#EA4335" p-id="4362"></path>
                <path
                  d="M516.693333 807.808c-134.357333 0-247.509333-84.864-287.232-202.88l-172.288 128.853333c83.242667 165.546667 257.152 278.826667 459.52 278.826667 124.842667 0 244.053333-43.392 333.568-124.757333l-163.584-123.818667c-46.122667 28.458667-104.234667 43.776-170.026666 43.776"
                  fill="#34A853" p-id="4363"></path>
                <path
                  d="M1005.397333 512c0-29.568-4.693333-61.44-11.648-91.008H516.650667V614.4h274.602666c-13.696 65.962667-51.072 116.650667-104.533333 149.632l163.541333 123.818667c93.994667-85.418667 155.136-212.650667 155.136-375.850667"
                  fill="#4285F4" p-id="4364"></path>
              </svg>
            </v-btn>
          </v-row>
        </v-card-text>

      </v-card>

      <!-- 注册码卡片 -->
      <v-card elevation="2" class="pa-5">
        <v-card-title v-show="!reg_config.open_register">注册码</v-card-title>
        <v-card-text class="mt-3" v-show="!reg_config.open_register">
          <v-text-field outlined v-model="reg_code" :disabled="disable_input" :readonly="readonly_input"></v-text-field>
        </v-card-text>

        <v-card-title>基本信息</v-card-title>
        <v-card-text class="mt-3">
          <v-text-field outlined v-model="username" :disabled="disable_input" label="用户名"
            :readonly="readonly_input"></v-text-field>
          <v-text-field outlined type="email" v-model="email" :disabled="disable_input" label="邮箱"
            :readonly="readonly_input"></v-text-field>
          <v-row>
            <v-col>
              <v-text-field outlined v-model="email_code" :disabled="disable_input" label="邮箱验证码"
                :readonly="readonly_input"></v-text-field>
            </v-col>
            <v-col>
              <v-btn large depressed @click="do_send_email_code" :disabled="send_btn.disabled">{{ send_btn.text
                }}</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-title>密码</v-card-title>
        <v-card-text>
          <v-text-field outlined v-model="password" type="password" :disabled="disable_input" label="密码"
            :readonly="readonly_input"></v-text-field>
          <v-text-field outlined v-model="password_c" type="password" :disabled="disable_input" label="确认密码"
            :readonly="readonly_input"></v-text-field>
        </v-card-text>


        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn width="100px" :loading="loading" :disabled="disable_input" @click="submit"
            @keyup.enter.native="submit">提交</v-btn>
        </v-card-actions>
      </v-card>
      <!-- 注册码卡片 -->

    </v-container>
    <!-- 注册表单 -->

    <!-- 验证码框 -->
    <v-dialog v-model="captcha_dialog.dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">您需要输入验证码</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <!-- 内容 -->
            <v-row class="mx-1">
              <v-img :src="captcha_dialog.img" max-width="210px" max-height="70px"
                @click="getCaptcha(captcha_dialog.type)"></v-img>
              <v-text-field class="ma-2" v-model="captcha_dialog.input" :disabled="disable_input" label="验证码"
                outlined></v-text-field>
            </v-row>
            <!-- 内容 -->
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="do_send_email_code" v-if="captcha_dialog.type == 0"> 发送验证码 </v-btn>
          <v-btn color="blue darken-1" text @click="submit" v-if="captcha_dialog.type == 1"> 提交注册 </v-btn>
          <v-btn color="" text @click="captcha_dialog.dialog = false"> 取消 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- 提示框 -->
    <v-dialog v-model="config.alert" max-width="450px">
      <v-card>
        <v-card-title>{{ config.alert_type }}</v-card-title>
        <v-card-text>
          {{ config.alert_text }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="config.alert = !config.alert">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "reg",
  data: function () {
    return {
      view: 0,
      reg_code: "",
      username: "",
      email: "",
      email_code: "",
      password: "",
      password_c: "",
      disable_input: false,
      readonly_input: true,
      loading: false,
      config: {
        alert_text: "",
        alert_type: "info",
        alert: false,
      },
      reg_config: {
        open_register: false,
        announcement: "",
        color: "",
        icon: "",
      },
      sso: "",
      text: "",
      captcha_dialog: {
        dialog: false,
        type: 0,//0为发送邮箱验证码，1为注册
        img: "",
        input: "",
      },
      send_btn: {
        count: "",
        disabled: false,
        text: "获取",
        timer: null
      }
    };
  },
  mounted: function () {
    var _this = this;
    setTimeout(() => {
      _this.readonly_input = false;
    }, 500);
    if (
      this.$route.query.reg_code != "" &&
      this.$route.query.reg_code != null
    ) {
      this.reg_code = this.$route.query.reg_code;
    }
    this.getConfig();
  },
  methods: {
    getConfig: function () {
      this.axios({
        url: "/api/auth/regConfig",
        method: "get",
      })
        .then((res) => {
          if (res.data.status == 0) {
            //ok
            this.reg_config = res.data.data;
          } else {
            this.error(this.parseError(res));
          }
        })
        .catch((err) => {
          console.log(err);
          this.error("登录失败:" + err);
        });
    },
    getCaptcha: function (type) {
      this.captcha_dialog.input = "";
      this.captcha_dialog.type = type;
      this.axios({
        url: "/api/captcha",
        method: "get",
      })
        .then((res) => {
          if (res.data.status == 0) {
            //ok
            this.captcha_dialog.dialog = true;
            this.captcha_dialog.img = res.data.data.image;
          } else {
            this.error(this.parseError(res));
          }
        })
        .catch((err) => {
          console.log(err);
          this.error("登录失败:" + err);
        });
    },
    do_send_email_code: function () {
      var _this = this;
      this.axios({
        url: "/api/auth/reg/send",
        method: "post",
        data: {
          captcha_code: this.captcha_dialog.input,
          email: this.email
        }
      })
        .then((res) => {
          if (res.data.status == 0) {
            //ok
            this.send_btn.count = 60;
            this.send_btn.disabled = true;
            this.count_down();
            this.send_btn.timer = setInterval(function () {
              _this.count_down();
            }, 1000);
            this.success("验证码已发送，请注意查收！");
          } else if (res.data.status == -1007) {
            this.getCaptcha(0);
          } else {
            this.error(this.parseError(res));
          }
          if (this.captcha_dialog.dialog == true) {
            this.captcha_dialog.dialog = false;
            this.captcha_dialog.input = "";
          }
        })
        .catch((err) => {
          this.error("失败:" + err);
        });
    },
    count_down: function () {
      this.send_btn.count--;
      this.send_btn.text = "重新获取(" + this.send_btn.count + "秒)";
      if (this.send_btn.count < 1) {
        clearInterval(this.send_btn.timer);
        this.send_btn.timer = null;
        this.send_btn.text = "重新获取";
        this.send_btn.disabled = false;
      }
    },
    submit: function () {
      if (!this.reg_config.open_register && !this.reg_code) {
        this.error("注册码不能为空");
        return;
      }
      if (!(this.username || this.password)) {
        this.error("请输入用户名和密码");
        return;
      }
      if (this.password != this.password_c) {
        this.error("两次输入的密码不一致");
        return;
      }
      this.axios({
        url: "/api/auth/reg",
        method: "post",
        data: {
          reg_code: this.reg_code,
          username: this.username,
          email: this.email,
          email_code: this.email_code,
          password: this.password,
          captcha: this.captcha_dialog.input,
        },
      })
        .then((res) => {
          if (res.data.status == 0) {
            this.success("注册成功");
            if (res.data.data.redirect_url != "") {
              setTimeout(function () {
                this.$router.push("/login");
              }, 2000);
            }
            this.view = 1;
            this.text = res.data.data.text;
          } else {
            switch (res.data.status) {
              case -1007:
                this.getCaptcha(1);
                break
              case -1008:
                this.error(this.parseError(res));
                this.getCaptcha(1);
                break
              default:
                this.error(this.parseError(res));
            }
          }
          if (this.captcha_dialog.dialog == true) {
            this.captcha_dialog.dialog = false;
            this.captcha_dialog.input = "";
          }
        })
        .catch((err) => {
          console.log(err);
          this.error("登录失败:" + err);
        });

      const that = this;
      setTimeout(function () {
        if (that.alert) {
          that.config.alert = !that.config.alert;
        }
      }, 2000);
    },
    success: function (text) {
      this.config.alert = !this.config.alert;
      this.config.alert_text = text;
      this.config.alert_type = "成功";
    },
    error: function (err) {
      this.config.alert = !this.config.alert;
      this.config.alert_text = err;
      this.config.alert_type = "失败";
    },
  },
};
</script>

<style></style>
